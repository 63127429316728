import { tailwind } from '@theme-ui/presets'

const theme = {
  ...tailwind,
  styles: {
    ...tailwind.styles,
  },
  colors: {
    ...tailwind.colors,
    primary: '#04acd6',
    headerBg: '#F8F8F8',
    footerBg: '#F5F8FA',
    topicBg: '#d6f4fc',
    topicBorder: '#00bfef',
    topicShadow: '#7ca7b2',
    muted: '#888',

  },
  text: {
    ...tailwind.text,
    h1: {
      fontFamily: 'heading',
      fontWeight: 'normal',
      fontSize: 4,
      lineHeight: 'heading',
      mb: 4,
    },
    h2: {
      fontFamily: 'heading',
      fontWeight: 'normal',
      fontSize: 3,
      lineHeight: 'heading',
      mb: 3,
    },
    sidenav: {
      fontFamily: 'heading',
      fontWeight: 'normal',
      fontSize: 2,
      lineHeight: 'heading',
      m: 0,
    },
  }
}

export default theme