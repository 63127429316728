// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-apps-js": () => import("./../../../src/pages/apps.js" /* webpackChunkName: "component---src-pages-apps-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-templates-app-js": () => import("./../../../src/templates/app.js" /* webpackChunkName: "component---src-templates-app-js" */),
  "component---src-templates-article-js": () => import("./../../../src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-templates-topic-js": () => import("./../../../src/templates/topic.js" /* webpackChunkName: "component---src-templates-topic-js" */)
}

